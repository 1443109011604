<template>
  <div class="headline">
    <div class="title">
      <h3>{{content.title}}</h3>
      <p>
        {{content.create_time}}
      </p>
      
    </div>
    <div class="content" v-html="content.content">

    </div>
  </div>
</template>

<script>
import {
  hanerLine,
} from "@/api/index";
export default {
  data(){
    return {
        content:''
    }
  },
  created(){
    this.initTbel();
  },
  methods:{
    initTbel(){
        let datas = {
            id:this.$route.query.id,
        }
        hanerLine(datas).then(res=>{
            this.content = res.data
        })
    }
  }
}
</script>

<style scoped lang="less">
.headline{
  background: #fff;
  padding: 10px;
}
.title{
  h3{
    text-align: left;
    font-size: 20px;
    padding: 0 0 0 4px;
    margin: 17px 0 0 0;
  }
  p{
    text-align: left;
    font-size: 12px;
    padding: 15px 0 0 6px;
    margin: 0;
    color:#A7A7A7
  }
  span{
    padding-left: 11px;
  }
  img{
    height: 14px;
    position: relative;
    top: 2px;
  }
  strong{
    padding-left: 5px;
    font-weight: normal;
  }
}
.content{
  padding-top: 30px;
  width: 100%;
  word-wrap: break-word;
  text-align: left;
}
</style>
